import React, { Component, Fragment } from 'react'
import Parser from 'html-react-parser';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

export class Why extends Component {

  constructor(){
    super();
    this.state={
    title:"",
    subtitle:"",
    description:"",
    }
}

componentDidMount(){
  RestClient.GetRequest(AppUrl.WhySection).then(result=>{
    this.setState({
          title:result[0]['title'],
          subtitle:result[0]['subtitle'],
          description:result[0]['description'],
    });
  }) 
}

  render() {
    return (
      <Fragment>
      <section className='why'>
      <Container>
          <Row>
            <Col xs={12} md={6} lg={6}>
                <picture>
                    <source srcSet="images/pagini/beneficii-drenaj-limfatic.webp" alt="" title="" width="630" height="480" type="image/webp" />
                    <img src="images/pagini/beneficii-drenaj-limfatic.webp" alt="" title="" width="630" height="480" />
                </picture>
            </Col>
            <Col xs={12} md={6} lg={6}>
                <span>{this.state.subtitle}</span>
                <h4>{this.state.title}</h4>
                {Parser(this.state.description)}
                <a title="Citește mai mult" rel="noopener" href="beneficii-drenaj-limfatic"> 
                    <Button variant="btn btn-outline-light">Citește mai mult</Button>
                </a>
            </Col>
          </Row>
        </Container>
      </section>
      </Fragment>
    )
  }
}

export default Why