import React, { Component, Fragment } from 'react'
import Parser from 'html-react-parser';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

export class Beneficii extends Component {
        constructor(){
            super();
            this.state={
            title:"",
            subtitle:"",
            description:"",
            }
        }
        
        componentDidMount(){
          RestClient.GetRequest(AppUrl.BeneficiiSection).then(result=>{
            this.setState({
                  title:result[0]['title'],
                  subtitle:result[0]['subtitle'],
                  description:result[0]['description'],
            });
          }) 
        }
  render() {
    return (
      <Fragment>

        <section className='what'>
            <Container>
              <Row>
                <Col xs={12} md={6} lg={6}>
                    <span>{this.state.subtitle}</span>
                    <h2>{this.state.title}</h2>
                    {Parser(this.state.description)}
                </Col>
                <Col xs={12} md={6} lg={6}>
                    <picture>
                        <source srcSet="images/pagini/beneficii-drenaj-limfatic.webp" alt={this.state.title} title={this.state.title} width="630" height="480" type="image/webp" />
                        <img src="images/pagini/beneficii-drenaj-limfatic.webp" alt={this.state.title} title={this.state.title} width="630" height="480" />
                    </picture>
                </Col>
              </Row>
            </Container>
          </section>

      </Fragment>
    )
  }
}

export default Beneficii