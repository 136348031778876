import React, { Component, Fragment } from 'react'

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

export class Block extends Component {

    constructor(){
        super();
        this.state={
        title:"",
        subtitle:"",
        description:"",
        }
    }
    
      componentDidMount(){
        RestClient.GetRequest(AppUrl.BlockSection).then(result=>{
          this.setState({
                title:result[0]['title'],
                subtitle:result[0]['subtitle'],
                description:result[0]['description'],
          });
        }) 
      }

  render() {
    return (
      <Fragment>
                <span>{this.state.subtitle}</span>
                <h6>{this.state.title}</h6>
      </Fragment>
    )
  }
}

export default Block