import React, { Component, Fragment } from 'react'
import Parser from 'html-react-parser';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

export class CTA extends Component {

  constructor(){
    super();
    this.state={
    title:"",
    subtitle:"",
    description:"",
    }
}

  componentDidMount(){
    RestClient.GetRequest(AppUrl.CTASection).then(result=>{
      this.setState({
            title:result[0]['title'],
            subtitle:result[0]['subtitle'],
            description:result[0]['description'],
      });
    }) 
  }

  render() {
    return (
      <>
      <section className='cta'>
      <Container>
          <Row>
            <Col xs={12} md={12} lg={12}>
                <span>{this.state.subtitle}</span>
                <h5>{this.state.title}</h5>
                {Parser(this.state.description)}
                <br/>
                <a title="Sună acum" rel="noopener" href="tel:+40749115772"> 
                    <Button variant="btn btn-outline-light">Sună acum</Button>
                </a>
            </Col>
          </Row>
        </Container>
      </section>
      </>
    )
  }
}

export default CTA