import React, { Component, Fragment } from 'react'
import Parser from 'html-react-parser';

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

export class Testimonials extends Component {

  constructor(){
    super();
    this.state={
    title:"",
    subtitle:"",
    description:"",
    }
}

  componentDidMount(){
    RestClient.GetRequest(AppUrl.TestimonialSection).then(result=>{
      this.setState({
            title:result[0]['title'],
            subtitle:result[0]['subtitle'],
            description:result[0]['description'],
      });
    }) 
  }

  render() {
    return (
      <Fragment>
      <section className='testimonials'>
        <div class="card">
            <picture>
                <source srcSet="images/pagini/testimonials.webp" alt="" title="" width="820" height="500" type="image/webp" />
                <img src="images/pagini/testimonials.webp" alt="" title="" width="820" height="500" />
            </picture>
            <div class="card-body">
                <span>{this.state.subtitle}</span>
                <h6 class="card-title">{this.state.title}</h6>
                <p class="card-text">{Parser(this.state.description)}</p>
                <a title="Citește mai mult" href="testimoniale" class="card-link">Citește mai mult</a>
            </div>
        </div>
      </section>
      </Fragment>
    )
  }
}

export default Testimonials