import React, { Component, Fragment } from 'react'
import axios from "axios";

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export class GalleryList extends Component {

  constructor(){
      super();
      this.state={
        galleryData:[] 
    }
  }

  componentDidMount(){
    RestClient.GetRequest(AppUrl.Gallery).then(result=>{
      this.setState({galleryData:result});
    }) 
  }
  
    getImages = () => {
    axios
      .get("https://vsoft.drenaj-limfatic-braila.ro/")
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            images: response.data.data,
          });
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {

    const ImageList = this.state.galleryData;
    const ImageView = ImageList.map((ImageList, index)=>{

    return <Col xs={6} md={4} lg={4} className="gallery_list" key={index}>
      <a title={ImageList.image_title} href={ "https://vsoft.drenaj-limfatic-braila.ro/" + ImageList.image_url } data-lightbox="galerie-set" data-title=""><img src={ "https://vsoft.drenaj-limfatic-braila.ro/" + ImageList.image_url } alt={ImageList.image_alt} title={ImageList.image_title} /></a>
    </Col>  

    })

    return (
    <Fragment>
      <Container>
        <Row>
          {ImageView}
        </Row>
      </Container>
    </Fragment>
    )
  }

}

export default GalleryList