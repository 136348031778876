import React, { Component } from 'react'

import Carousel from 'react-bootstrap/Carousel';
import {Animated} from "react-animated-css";
import Button from 'react-bootstrap/Button';

export class Slider extends Component {

  render() {
    return (
      <div>
        <div className="slideshow">
          <Carousel fade>

            <Carousel.Item>
              <img
                className="slider d-block w-100"
                src="images/slide/slide1.webp"
                alt="Drenaj limfatic"
                title="Drenaj limfatic"
				width="1350" 
				height="700"
              />
              <Carousel.Caption>
                <Animated animationIn="bounceInUp" animationOut="fadeOut" animationInDelay="800" animationInDuration="1200" isVisible={true}>
                  <span>Drenaj limfatic</span>
                </Animated>
                <Animated animationIn="bounceInDown" animationOut="fadeOut" animationInDelay="800" animationInDuration="1200" isVisible={true}>
                  <p>sau, cum să  elimini mai ușor grăsimea, toxinele, apa în exces și celulita, din organism</p>
                </Animated>
                <Animated animationIn="bounceInUp" animationOut="fadeOut" animationInDelay="1200" animationInDuration="2000" isVisible={true}>
                  <a title="Contactează-ne acum" rel="noopener" href="tel:+407491157721"> 
                    <Button variant="outline-light">Contactează-ne acum</Button>
                  </a>
                </Animated>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="slider d-block w-100"
                src="images/slide/slide2.webp"
                alt="Cere acum o programare"
                title="Cere acum o programare"
				width="1350" 
				height="700"
              />
              <Carousel.Caption>
                <Animated animationIn="bounceInLeft" animationOut="fadeOut" animationInDelay="600" animationInDuration="1200" isVisible={true}>
                  <span>Sebastian Kneipp</span>
                </Animated>
                <Animated animationIn="bounceInRight" animationOut="fadeOut" animationInDelay="1000" animationInDuration="1200" isVisible={true}>
                  <p>„Toți doresc să fie sănătoși și să aibă o viață lungă, dar puțini fac ceva pentru asta.”</p>
                </Animated>
                <Animated animationIn="bounceInUp" animationOut="fadeOut" animationInDelay="1200" animationInDuration="2000" isVisible={true}>
                  <a title="Cere acum o programare" rel="noopener" href="tel:+407491157721"> 
                    <Button variant="outline-light">Cere acum o programare</Button>
                  </a>
                </Animated>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="slider d-block w-100"
                src="images/slide/slide3.webp"
                alt="Sună acum pentru o sedinta"
                title="Sună acum pentru o sedinta"
				width="1350" 
				height="700"
              />
              <Carousel.Caption>
                <Animated animationIn="bounceInRight" animationOut="fadeOut" animationInDelay="600" animationInDuration="1200" isVisible={true}>
                  <span>Sebastian Kneipp</span>
                  <p>,,Cel ce nu dorește să investească zilnic în sănătatea lui va fi nevoit într-o zi să sacrifice mult din timpul său pentru boală.”</p>
                </Animated>
                <Animated animationIn="bounceInUp" animationOut="fadeOut" animationInDelay="1200" animationInDuration="2000" isVisible={true}>
                  <a title="Tu ce mai aștepți? Sună acum!" rel="noopener" href="tel:+407491157721"> 
                    <Button variant="outline-light">Tu ce mai aștepți? Sună acum!</Button>
                  </a>
                </Animated>
              </Carousel.Caption>
            </Carousel.Item>

            </Carousel>
     
        </div>
      </div>
    )
  }
}

export default Slider