import React, { Component, Fragment } from 'react'
import Parser from 'html-react-parser';

import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

export class Packages extends Component {

  constructor(){
      super();
      this.state={
        PackagesData:[] 
    }
  }

  componentDidMount(){
    RestClient.GetRequest(AppUrl.Packages).then(result=>{
      this.setState({PackagesData:result});
    }) 
  }

render() {

  const PackagesList = this.state.PackagesData;
  const PackagesView = PackagesList.map((PackagesList, index)=>{

  return <Col xs={12} md={3} lg={3} className="price" key={index}>

        <h3>{PackagesList.package_title}</h3>
        <h4>{PackagesList.package_price} <sup>{PackagesList.package_currency}</sup><span>{PackagesList.package_duration} </span></h4>

        {Parser(PackagesList.package_description)}
        <a title="Sună acum" rel="noopener" href="tel:+40749115772"> 
            <Button variant="btn btn-success">
                Sună acum 
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right" viewBox="0 0 16 16">
                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
                </svg>
            </Button>
        </a>
    
  </Col>  

  })

  return (
  <Fragment>
    {PackagesView}
  </Fragment>
  )
}

}

export default Packages