import React, { Component, Fragment } from 'react'

import { Helmet } from 'react-helmet-async';
import {Animated} from "react-animated-css";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

import FaqList from '../components/FaqList';
import FaqSection from '../components/FaqSection';

export class IntrebariFrecvente extends Component {

    constructor(){
    super();
    this.state={
      page_title:"",
      page_content:"",
      page_image:"",
      page_meta_title:"",
      page_meta_description:"",
    }
}

  componentDidMount(){
    RestClient.GetRequest(AppUrl.IntrebariPage).then(result=>{
      this.setState({
        page_title:result[0]['page_title'],
        page_content:result[0]['page_content'],
        page_image:result[0]['page_image'],
        page_meta_title:result[0]['page_meta_title'],
        page_meta_description:result[0]['page_meta_description'],
      });
    }) 
  }

  render() {
    return (
      <Fragment>
        <Helmet>
            <title>{this.state.page_meta_title}</title>
            <link rel="canonical" href="/intrebari-frecvente" />
            <meta name="description" content={this.state.page_meta_description}/>
            
            <meta property="og:title" content={this.state.page_meta_title}/>
            <meta property="og:type" content="website"/>
            <meta property="og:url" content="/intrebari-frecvente"/>
            <meta property="og:description" content={this.state.page_meta_description}/>
            <meta property="og:image" content="images/pagini/intrebari-frecvente.webp"/>

            <meta name="twitter:card" content="summary"/>
            <meta name="twitter:url" content="/intrebari-frecvente"/>
            <meta name="twitter:site" content="@drenajlimfaticbraila"/>
            <meta name="twitter:title" content={this.state.page_meta_title}/>
            <meta name="twitter:description" content={this.state.page_meta_description}/>
            <meta name="twitter:image" content="images/pagini/intrebari-frecvente.webp"/>
        </Helmet>

        <Container fluid>
          <Row>
            <Col xs={12} md={12} lg={12} className="page_heading">
               <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{this.state.page_title} </li>
                  </ol>
                </nav>
                <Animated animationIn="bounceInRight" animationOut="fadeOut" animationInDelay="1200" animationInDuration="1500" isVisible={true}>
                    <div>
                        <h1> {this.state.page_title} </h1>
                    </div>
              </Animated>
            </Col>
          </Row>
        </Container>

        <section className="page_block">
          <Container>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <FaqSection />
              </Col>
            </Row>
          </Container>
        </section>

        <section className="page_block">
          <Container>
            <Row>
              <Col xs={12} md={4} lg={4}>
                <picture>
                  <source srcSet="images/pagini/despre.webp" alt="" title="" width="610" height="570" type="image/webp" />
                  <img src="images/pagini/despre.webp" alt="" title="" width="610" height="570" />
                </picture>
              </Col>
              <Col xs={12} md={8} lg={8}>
                <div className='faq'>

                  <Accordion defaultActiveKey="0" flush>
                    <FaqList />
                  </Accordion>
                  
                </div>
              </Col>
            </Row>
          </Container>
        </section>

      </Fragment>
    )
  }
}

export default IntrebariFrecvente