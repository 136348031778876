import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import Header from './common/Header';
import Footer from './common/Footer';

import Home from './pages/Home';
import Despre from './pages/Despre';
import DrenajLimfatic from './pages/DrenajLimfatic';
import Beneficii from './pages/Beneficii';
import Galerie from './pages/Galerie';
import Contact from './pages/Contact';
import Programare from './pages/Programare';
import IntrebariFrecvente from './pages/IntrebariFrecvente';
import Testimoniale from './pages/Testimoniale';
import Termeni from './pages/Termeni';
import Cookies from './pages/Cookies';
import Confidentialitate from './pages/Confidentialitate';

function App() {
  return (
    <div>
      <Header />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/despre-noi" element={<Despre />} />
            <Route path="/drenaj-limfatic" element={<DrenajLimfatic />} />
            <Route path="/beneficii-drenaj-limfatic" element={<Beneficii />} />
            <Route path="/galerie" element={<Galerie />} />
            <Route path="/programare" element={<Programare />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/intrebari-frecvente" element={<IntrebariFrecvente />} />
            <Route path="/testimoniale" element={<Testimoniale />} />
            <Route path="/termeni-conditii" element={<Termeni />} />
            <Route path="/cookies" element={<Cookies />} />
            <Route path="/politica-confidentialitate" element={<Confidentialitate />} />
          </Routes>
        </BrowserRouter>

        <CookieConsent
          location="bottom"
          buttonText="Accept"
          style={{ background: "#539165" }}
          buttonStyle={{  background: "#3F497F", color: "#F8F5E4", border: "1px solid #F8F5E4", fontSize: "13px" }}
          expires={150}
        >
          Folosim cookie-uri pentru a vă asigura o experiență mai buna pe website-ul nostru. Prin continuarea navigării, vă dați acordul. Vezi <a title="Politica de utilizare cookie" href="/cookies">politica cookies</a> 
        
        </CookieConsent>

      <Footer />
    </div>
  );
}

export default App;
