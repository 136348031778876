import React, { Component, Fragment } from 'react'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Slider from '../components/Slider'
import About from '../components/About';
import Featured from '../components/Featured';
import Steps from '../components/Steps';
import What from '../components/What';
import Why from '../components/Why';
import CTA from '../components/CTA';
import Faq from '../components/Faq';
import Testimonials from '../components/Testimonials';
import Appointment from '../components/Appointment';
import Block from '../components/Block';

export class Cookies extends Component {

  render() {
    return (
      <Fragment>
	  
        <Slider />
        <About />
        <Featured />
        <Steps />
        <What />
        <Why />
        <CTA />
        <section className='group'>
          <Container>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <Block/>
              </Col>
              <Col xs={12} md={4} lg={4}>
                  <Faq />
              </Col>
              <Col xs={12} md={4} lg={4}>
                  <Testimonials />
              </Col>
              <Col xs={12} md={4} lg={4}>
                  <Appointment />
              </Col>

            </Row>
          </Container>
        </section>
      </Fragment>
    )
  }
}

export default Cookies