import React, { Component, Fragment } from 'react'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';
import StepSection from './StepSection';

export class Steps extends Component {

  constructor(){
      super();
      this.state={
        StepsData:[] 
    }
  }

  componentDidMount(){
    RestClient.GetRequest(AppUrl.Steps).then(result=>{
      this.setState({StepsData:result});
    }) 
  }

render() {

  const StepsList = this.state.StepsData;
  const StepsView = StepsList.map((StepsList, index)=>{

  return <Col xs={12} md={3} lg={3} key={index}>
    <div className='step_block'>
      <span>{StepsList.step_number}</span>
      <h3>{StepsList.step_title}</h3>
      {StepsList.step_description}
    </div>  
  </Col>  

  })

  return (
  <Fragment>
     <section className='steps'>
      <Container fluid>
        <Row>
          <StepSection/>
          {StepsView}
        </Row>
      </Container>
    </section>
  </Fragment>
  )
}

}

export default Steps