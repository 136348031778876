import React, { Component, Fragment } from 'react'
import Parser from 'html-react-parser';

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

import Accordion from 'react-bootstrap/Accordion';

export class FaqList extends Component {
    constructor(){
        super();
        this.state={
          faqData:[] 
      }
    }
  
    componentDidMount(){
      RestClient.GetRequest(AppUrl.Faq).then(result=>{
        this.setState({faqData:result});
      }) 
    }
  
    render() {
  
      const FaqList = this.state.faqData;
      const FaqView = FaqList.map((FaqList, index)=>{
  
      return <div key={index}>
        <Accordion.Item eventKey={FaqList.id} >
            <Accordion.Header>{FaqList.faq_question} </Accordion.Header>
            <Accordion.Body>
            {Parser(FaqList.faq_answer)}
            </Accordion.Body>
        </Accordion.Item>
      </div>  
  
      })
  
      return (
      <Fragment>

            {FaqView}

      </Fragment>
      )
    }
  
  }

export default FaqList