import React, { Component, Fragment } from 'react'
import Parser from 'html-react-parser';

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export class Footer extends Component {

  constructor(){
		super();
		this.state={
      mobile:"",
      email:"",
      copyright:"",
      facebook_url:"",
		}
   }

  componentDidMount(){
		RestClient.GetRequest(AppUrl.Settings).then(result=>{
			this.setState({
				mobile:result[0]['mobile'],
				email:result[0]['email'],
        copyright:result[0]['copyright'],
        facebook_url:result[0]['facebook_url'],
			});
		}) 
	}

  render() {
    return (
      <Fragment>
      <section className='footer'>
        <Container>
          <Row>
            <Col xs={12} md={3} lg={3}>
              <a title="logo" href="/">				
                <picture>
                  <source srcSet="images/logo-contrast.webp" alt="Drenaj Limfatic Braila" title="Drenaj Limfatic Braila" width="500" height="155" type="image/webp" />
                  <img className='logo_footer' src="images/logo-contrast.webp" alt="Drenaj Limfatic Braila" title="Drenaj Limfatic Braila" width="500" height="155" />
                </picture>
              </a>
            </Col>
            <Col xs={12} md={4} lg={4}>
                <div className='footer_nav'>
                  <h6>Contact</h6>
                  <ul>
                    <li>Telefon: {this.state.mobile}</li>
                    <li>Email: {this.state.email}</li>
                  </ul>
                </div>
            </Col>
            <Col xs={12} md={2} lg={2}>
                <div className='footer_nav'>
                  <h6>Social media</h6>
                  <ul>
                    <li><a title="Facebook" target="_blank" rel="noreferrer" href={Parser(this.state.facebook_url)}>Facebook </a></li>
                  </ul>
                </div>
            </Col>
            <Col xs={12} md={3} lg={3}>
                <div className='footer_nav'>
                  <h6>Utile</h6>
                  <ul>
                    <li><a title="Termeni si conditii" href="/termeni-conditii">Termeni si conditii</a></li>
                    <li><a title="Politica de confidentialitate" href="/politica-confidentialitate">Politica de confidentialitate</a></li>
                    <li><a title="Cookies" href="/cookies">Cookies</a></li>
                    <li>
                      <a title="Soluționarea Alternativă a Litigiilor" href="https://anpc.ro/ce-este-sal/">
                      <picture>
                        <source srcSet="images/SAL.svg" alt="Soluționarea Alternativă a Litigiilor" title="Soluționarea Alternativă a Litigiilor" width="120" height="29" type="image/webp" />
                        <img  src="images/SAL.svg" alt="Soluționarea Alternativă a Litigiilor" title="Soluționarea Alternativă a Litigiilor" width="120" height="29" />
                      </picture>
                      </a>
                    </li>
                    <li>
                      <a title="Soluționarea online a litigiilor" href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO">
                      <picture>
                        <source srcSet="images/SOL.svg" alt="Soluționarea online a litigiilor" title="Soluționarea online a litigiilor" width="120" height="29" type="image/webp" />
                        <img  src="images/SOL.svg" alt="Soluționarea online a litigiilor" title="Soluționarea online a litigiilor" width="120" height="29" />
                      </picture>
                      </a>
                    </li>
                  </ul>
                </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col xs={12} md={6} lg={6} className='copyright'>
              {this.state.copyright}
            </Col>
            <Col xs={12} md={6} lg={6} className='development'>
              <p>
                Created with ❤️ by 
                <a title="Creare site web" href="https://www.dualcv.net">				
                <picture>
                  <source srcSet="https://www.dualcv.net/dualvalcost-contrast.webp" alt="Creare site web" title="Creare site web" width="120" height="29" type="image/webp" />
                  <img src="https://www.dualcv.net/dualvalcost-contrast.webp" alt="Creare site web" title="Creare site web" width="120" height="29" />
                </picture>
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      </Fragment>
    )
  }
}

export default Footer