import React, { Component, Fragment } from 'react'

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

export class Appointment extends Component {

  constructor(){
    super();
    this.state={
    title:"",
    }
}

  componentDidMount(){
    RestClient.GetRequest(AppUrl.BannerSection).then(result=>{
      this.setState({
            title:result[0]['title'],
      });
    }) 
  }

  render() {
    return (
      <>
      <section className='appointment'>
        <picture>
            <source srcSet="images/pagini/programare.webp" alt="" title="" width="440" height="610" type="image/webp" />
            <img src="images/pagini/programare.webp" alt="" title="" width="440" height="610" />
        </picture>
        <div className='appointment_content'>
            <h6>{this.state.title}</h6>
            <a title="Sună acum" rel="noopener" href="tel:+40749115772"> 
            Sună acum
            </a>
        </div>
      </section>
      </>
    )
  }
}

export default Appointment