import React, { Component, Fragment } from 'react'
import Parser from 'html-react-parser';

import Col from 'react-bootstrap/Col';

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

export class StepSection extends Component {

  constructor(){
      super();
      this.state={
      title:"",
      subtitle:"",
      description:"",
      }
  }

  componentDidMount(){
    RestClient.GetRequest(AppUrl.StepSection).then(result=>{
      this.setState({
            title:result[0]['title'],
            subtitle:result[0]['subtitle'],
            description:result[0]['description'],
      });
    }) 
  }

  render() {
    return (
      <Fragment>
            <Col xs={12} md={3} lg={3}>
                  <span>{this.state.subtitle}</span>
                  <h2>{this.state.title}</h2>
				  {Parser(this.state.description)}
            </Col>
      </Fragment>
    )
  }
}

export default StepSection