import React, { Component, Fragment } from 'react'
import Parser from 'html-react-parser';

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

export class PackagesSection extends Component {

    constructor(){
        super();
        this.state={
        title:"",
        subtitle:"",
        description:""
        }
    }

    componentDidMount(){
        RestClient.GetRequest(AppUrl.AppointmentSection).then(result=>{
            this.setState({
                title:result[0]['title'],
                subtitle:result[0]['subtitle'],
        description:result[0]['description'],
            });
        }) 
    }

  render() {
    return (
      <Fragment>
        <span>{this.state.subtitle}</span>
        <h2>{this.state.title}</h2>
		{Parser(this.state.description)}
      </Fragment>
    )
  }
}

export default PackagesSection