import React, { Component, Fragment } from 'react'
import { Helmet } from 'react-helmet-async';
import {Animated} from "react-animated-css";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

import ContactSection from '../components/ContactSection';

export class Contact extends Component {

  constructor(){
		super();
		this.state={
      company:"",
      address:"",
      fiscal_code:"",
      trade_register:"",
      mobile:"",
      email:""
		}
   }

  componentDidMount(){
		RestClient.GetRequest(AppUrl.Settings).then(result=>{
			this.setState({
        company:result[0]['company'],
        address:result[0]['address'],
        fiscal_code:result[0]['fiscal_code'],
        trade_register:result[0]['trade_register'],
				mobile:result[0]['mobile'],
				email:result[0]['email'],
			});
		}) 
	}

  render() {
    return (
      <Fragment>
        <Helmet>
            <title>Contact Drenaj Limfatic Braila</title>
            <link rel="canonical" href="/contact" />
            <meta name="description" content="Vrei mai multe informații despre drenajul limfatic sau dorești să iei legătură cu noi? Contactează-ne acum!"/>
            
            <meta property="og:title" content="Contact Drenaj Limfatic Braila"/>
            <meta property="og:type" content="website"/>
            <meta property="og:url" content="/contact"/>
            <meta property="og:description" content="Vrei mai multe informații despre drenajul limfatic sau dorești să iei legătură cu noi? Contactează-ne acum!"/>
            <meta property="og:image" content="images/pagini/contact.webp"/>

            <meta name="twitter:card" content="summary"/>
            <meta name="twitter:url" content="/contact"/>
            <meta name="twitter:site" content="@drenajlimfaticbraila"/>
            <meta name="twitter:title" content="Contact Drenaj Limfatic Braila"/>
            <meta name="twitter:description" content="Vrei mai multe informații despre drenajul limfatic sau dorești să iei legătură cu noi? Contactează-ne acum!"/>
            <meta name="twitter:image" content="images/pagini/contact.webp"/>
        </Helmet>

        <Container fluid>
          <Row>
            <Col xs={12} md={12} lg={12} className="page_heading">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Contact</li>
                </ol>
              </nav>
              <Animated animationIn="bounceInRight" animationOut="fadeOut" animationInDelay="1200" animationInDuration="1500" isVisible={true}>
                  <div>
                      <h1> Contact </h1>
                  </div>
              </Animated>
            </Col>
          </Row>
        </Container>

        <section className="page_block">
          <Container>
            <Row>
              <Col xs={12} md={6} lg={6}>
                <ContactSection />
                <p><strong>{this.state.company}</strong> </p>
                <span>CUI: {this.state.fiscal_code} / Reg.Com: {this.state.trade_register}</span>
                <hr/>
                <div className='contact_details'>
                  <p>
                    <strong>Adresa:</strong> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16">
                      <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z"/>
                    </svg>
                    {this.state.address}
                  </p>
                  <hr/>
                  <p>
                    <strong>Telefon:</strong>  
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                    </svg>
                    {this.state.mobile}
                  </p>
                  <hr/>
                  <p><strong>Email:</strong>            
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-paper" viewBox="0 0 16 16">
                      <path d="M4 0a2 2 0 0 0-2 2v1.133l-.941.502A2 2 0 0 0 0 5.4V14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V5.4a2 2 0 0 0-1.059-1.765L14 3.133V2a2 2 0 0 0-2-2H4Zm10 4.267.47.25A1 1 0 0 1 15 5.4v.817l-1 .6v-2.55Zm-1 3.15-3.75 2.25L8 8.917l-1.25.75L3 7.417V2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v5.417Zm-11-.6-1-.6V5.4a1 1 0 0 1 .53-.882L2 4.267v2.55Zm13 .566v5.734l-4.778-2.867L15 7.383Zm-.035 6.88A1 1 0 0 1 14 15H2a1 1 0 0 1-.965-.738L8 10.083l6.965 4.18ZM1 13.116V7.383l4.778 2.867L1 13.117Z"/>
                    </svg>
                    {this.state.email}
                  </p>
                </div>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <div className='google_maps'>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2808.388887694421!2d27.94956407812241!3d45.260149083109106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b729f7636e54e5%3A0xb94b31e089bf22e1!2zU3RyYWRhIFBpZXTEg8ibaWkgMTgsIEJyxINpbGE!5e0!3m2!1sro!2sro!4v1679600287951!5m2!1sro!2sro" width="100%" height="500" allowfullscreen="" title="harta" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

      </Fragment>
    )
  }
}

export default Contact