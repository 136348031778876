import React, { Component, Fragment } from 'react'
import Parser from 'html-react-parser';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

export class About extends Component {

    constructor(){
		super();
		this.state={
      title:"",
      subtitle:"",
      description:""
		}
   }

  componentDidMount(){
		RestClient.GetRequest(AppUrl.AboutSection).then(result=>{
			this.setState({
				title:result[0]['title'],
				subtitle:result[0]['subtitle'],
        description:result[0]['description'],
			});
		}) 
	}

  render() {
    return (
      <Fragment>
      <section className="about">
        <Container>
          <Row>
            <Col xs={12} md={6} lg={6}>
                <picture>
                    <source srcSet="images/pagini/despre.webp" alt="Despre drenaj limfatic Braila" title="Despre drenaj limfatic Braila" width="610" height="570" type="image/webp" />
                    <img src="images/pagini/despre.webp" alt="Despre drenaj limfatic Braila" title="Despre drenaj limfatic Braila" width="610" height="570" />
                </picture>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <div className='about_content'>
                <span>{this.state.subtitle}</span>
                <h1>{this.state.title}</h1>
				{Parser(this.state.description)}
              </div>
            </Col>
          </Row>
        </Container>
        </section>
      </Fragment>
    )
  }
}

export default About