class AppUrl {
	static BaseURL = 'https://vsoft.drenaj-limfatic-braila.ro/api';
	
	static Settings = this.BaseURL+'/settings';
	static Featured = this.BaseURL+'/featured';
	static AboutSection = this.BaseURL+'/about/section';
	static StepSection = this.BaseURL+'/steps/section';
	static AppointmentSection = this.BaseURL+'/appointment/section';
	static ContactSection = this.BaseURL+'/contact/section';
	static FaqSection = this.BaseURL+'/faq/section';
	static WhatSection = this.BaseURL+'/what/section';
	static WhySection = this.BaseURL+'/why/section';
	static CTASection = this.BaseURL+'/cta/section';
	static BlockSection = this.BaseURL+'/block/section';
	static FaqBlockSection = this.BaseURL+'/faqblock/section';
	static TestimonialSection = this.BaseURL+'/testimonial/section';
	static BannerSection = this.BaseURL+'/banner/section';
	static DrenajSection = this.BaseURL+'/drenaj/section';
	static BeneficiiSection = this.BaseURL+'/beneficii/section';
	static DetoxifiereSection = this.BaseURL+'/detoxifiere/section';
	static Steps = this.BaseURL+'/steps';
	static Packages = this.BaseURL+'/packages';
	static Gallery = this.BaseURL+'/gallery';
	static Faq = this.BaseURL+'/faq';
	static AboutPage = this.BaseURL+'/page/about';
	static DrenajPage = this.BaseURL+'/page/drenaj';
	static ProgramarePage = this.BaseURL+'/page/programare';
	static GalleryPage = this.BaseURL+'/page/gallery';
	static BeneficiiPage = this.BaseURL+'/page/beneficii';
	static IntrebariPage = this.BaseURL+'/page/intrebari';
	static TestimonialePage = this.BaseURL+'/page/testimoniale';
	static TermeniPage = this.BaseURL+'/page/termeni';
	static ConfidentialitatePage = this.BaseURL+'/page/confidentialitate';
	static CookiePage = this.BaseURL+'/page/cookie';
	static Slider = this.BaseURL+'/slider';
}

export default AppUrl